import React from 'react';
import { PostAdd } from '@material-ui/icons';
import IntlMessages from '../../../utils/IntlMessages';
import BuildIcon from '@material-ui/icons/Build';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import BusinessIcon from '@material-ui/icons/Business';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import SettingsInputAntennaIcon from '@material-ui/icons/SettingsInputAntenna';

// const Menu = () => {
//   const { authUser } = useSelector(({ auth }) => auth);
//   console.log("authUser",authUser)
// }

export const sidebarNavs = [
  {
    name: 'Menu',
    type: 'section',
    children: [
      {
        name: 'Companies',
        type: 'item',
        icon: <BusinessIcon />,
        link: '/companies',
      },
      {
        name: 'User Management',
        type: 'item',
        icon: <SupervisedUserCircleIcon />,
        link: '/usermanagement',
      },
      {
        name: 'Settings',
        type: 'item',
        icon: <BuildIcon />,
        link: '/settings',
      },
      {
        name: 'Academy CMS',
        type: 'item',
        icon: <QuestionAnswerIcon />,
        link: '/academy-cms',
      },
      {
        name: 'Broadcast Channel',
        type: 'item',
        icon: <SettingsInputAntennaIcon />,
        link: '/broadcast-channel',
      },
    ],
  },
];

export const sidebarNavsForSubAdmin = [
  {
    name: 'Menu',
    type: 'section',
    children: [
      {
        name: 'Companies',
        type: 'item',
        icon: <BusinessIcon />,
        link: '/companies',
      },
      {
        name: 'Settings',
        type: 'item',
        icon: <BuildIcon />,
        link: '/settings',
      },
    ],
  },
];

export const horizontalDefaultNavs = [
  {
    name: <IntlMessages id={'sidebar.main'} />,
    type: 'collapse',
    children: [
      {
        name: <IntlMessages id={'pages.samplePage'} />,
        type: 'item',
        icon: <PostAdd />,
        link: '/sample-page',
      },
    ],
  },
];

export const minimalHorizontalMenus = [
  {
    name: <IntlMessages id={'sidebar.main'} />,
    type: 'collapse',
    children: [
      {
        name: <IntlMessages id={'pages.samplePage'} />,
        type: 'item',
        icon: <PostAdd />,
        link: '/sample-page',
      },
    ],
  },
];
