import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import GridContainer from '../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import { useDispatch } from 'react-redux';
import CmtCard from '../../../@coremat/CmtCard';
import { alpha, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import AppTextInput from '../../../@jumbo/components/Common/formElements/AppTextInput';
import { TextareaAutosize, TextField, ListItemText } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getCollectionList, getEndUserList } from 'redux/actions/EndUserManagement';
import { useHistory } from 'react-router-dom';
import './BroadcastChannel.css';
import { createBroadcastChannel } from 'redux/actions/BroadcastChannel';
import { getCompaniesList } from 'redux/actions/Companies';

const variant = 'default';

const useStyles = makeStyles(theme => ({
  input: {
    display: 'none',
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& > *': {
      margin: theme.spacing(1),
    },
    '& .Mui-error': {
      color: 'red',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
    '& .MuiFormLabel-root': {
      color: '#909090',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      color: theme.palette.text.primary,
    },
    cardContentRoot: {
      padding: '0 !important',
    },

    scrollbarRoot: {
      height: 340,
    },
    badgeRoot: {
      color: theme.palette.common.white,
      borderRadius: 30,
      fontSize: 12,
      padding: '2px 10px',
      display: 'inline-block',
    },
    authThumb: {
      backgroundColor: alpha(theme.palette.primary.main, 0.12),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 20,
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '50%',
        order: 2,
      },
    },
    authContent: {
      padding: 30,
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: props => (props.variant === 'default' ? '50%' : '100%'),
        order: 1,
      },
      [theme.breakpoints.up('xl')]: {
        padding: 50,
      },
    },
    titleVersion: {
      // marginBottom: 14,
      letterSpacing: 0.15,
      marginRight: 24,
      color: theme.palette.text.primary,
    },
    headerTitle: {
      fontSize: 20,
      fontStyle: 'bold',
    },
    textFieldRoot: {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: alpha(theme.palette.common.dark, 0.12),
      },
    },
    formcontrolLabelRoot: {
      '& .MuiFormControlLabel-label': {
        [theme.breakpoints.down('xs')]: {
          fontSize: 12,
        },
      },
    },
    noRecord: {
      textAlign: 'center',
      height: '250px',
      fontSize: 16,
      color: theme.palette.text.disabled,
      fontWeight: theme.typography.fontWeightBold,
      [theme.breakpoints.up('lg')]: {
        fontSize: 20,
      },
      border: 'none',
    },
    tableCellRoot: {
      textAlign: 'center',
    },
  },
  tableHead: {
    '& .MuiTableCell-head': {
      fontWeight: 600,
    },
  },
  tableRowRoot: {
    '& .MuiTableCell-root': {
      fontWeight: 300,
    },
  },
  disabledText1: {
    fontSize: 16,
    fontStyle: 'italic',
    color: theme.palette.text.disabled,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.up('lg')]: {
      fontSize: 12,
    },
  },
  disabledText2: {
    marginLeft: '10px',
    fontSize: 16,
    fontStyle: 'italic',
    color: theme.palette.text.disabled,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.up('lg')]: {
      fontSize: 12,
    },
  },
  backButton: {
    marginBottom: '16px',
  },
  cancelButton: {
    backgroundColor: 'red',
  },
}));

const groupTypes = [
  { label: 'Free Users', _id: 'free' },
  { label: 'Paid Users', _id: 'paid' },
  { label: 'Marketing Test Users', _id: 'marketing' },
];

const BroadcastChannelPage = () => {
  const classes = useStyles({ variant });
  const history = useHistory();
  const [endUserList, setEndUserList] = useState([]);
  const [companiesList, setCompaniesList] = useState([]);
  const [collectionsList, setCollectionsList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedCollections, setSelectedCollections] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [timer, setTimer] = useState(10);
  const [isRunning, setIsRunning] = useState(false);
  const [formikValues, setFormikValues] = useState(null);
  const [formikActions, setFormikActions] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();

  const breadcrumbs = [
    { label: 'Home', link: '/' },
    { label: 'Broadcast Channel', isActive: true },
  ];

  useEffect(() => {
    let interval;
    if (isRunning && timer > 0) {
      interval = setInterval(() => {
        setTimer(prev => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsRunning(false);
      if (formikValues && formikActions) {
        BroadcastChannelFormHandler(formikValues, formikActions);
      }
    }
    return () => clearInterval(interval);
  }, [isRunning, timer, formikValues, formikActions]);

  useEffect(() => {
    const fetchData = () => {
      dispatch(
        getEndUserList('created_at', 'desc', '', 0, -1, '', 3, data => {
          setEndUserList(data?.data?.data || []);
        }),
      );
      dispatch(
        getCompaniesList('created_at', 'desc', '', 0, -1, '', data => {
          setCompaniesList(data?.data?.data || []);
        }),
      );
      dispatch(
        getCollectionList('created_at', 'desc', '', 0, -1, '', data => {
          setCollectionsList(data?.data || []);
        }),
      );
    };
    fetchData();
  }, [dispatch]);

  const getDisplayName = user => {
    if (user.first_name && user.email) {
      return `${user.first_name} (${user.email})`;
    } else if (user.first_name) {
      return `${user.first_name} (No Email)`;
    } else if (user.email) {
      return `No Name (${user.email})`;
    } else {
      return 'No Name/Email';
    }
  };

  const getDisplayCompaniesName = user => {
    if (user.name && user.ticker) {
      return `${user.name} (${user.ticker})`;
    } else if (user.name) {
      return `${user.first_name} (No Ticker)`;
    } else if (user.ticker) {
      return `No Name (${user.ticker})`;
    } else {
      return 'No Name/Ticker';
    }
  };

  const getGroupName = user => {
    if (user.label) {
      return `${user.label}`;
    } else {
      return 'No Group';
    }
  };

  const getDisplayCollectionName = user => {
    if (user.name) {
      return `${user.name}`;
    } else {
      return 'No Name';
    }
  };

  const allOptions = [{ _id: 'all', name: 'Select All' }, ...endUserList];

  const validationSchema = Yup.object()
    .shape({
      title: Yup.string().required('Title is required'),
      description: Yup.string().required('Description is required'),
      users: Yup.array(),
      companies: Yup.array(),
      collections: Yup.array(),
      group: Yup.string(),
    })
    .test('at-least-one', 'At least one of Users, Companies, Collections, or Group is required.', values => {
      const { users, companies, collections, group } = values || {};
      const isAnyFieldFilled =
        (Array.isArray(users) && users.length > 0) ||
        (Array.isArray(companies) && companies.length > 0) ||
        (Array.isArray(collections) && collections.length > 0) ||
        (typeof group === 'string' && group.trim() !== '');
      if (!isAnyFieldFilled) {
        setErrorMessage('At least one of Users, Companies, Collections, or Group is required');
      } else {
        setErrorMessage('');
      }
      console.log('isAnyFieldFilled1', isAnyFieldFilled);

      return isAnyFieldFilled;
    });

  const BroadcastChannelFormHandler = async (values, actions) => {
    const formData = new FormData();

    console.log('values?.title', values, values?.title);

    formData.append('title', values?.title);
    formData.append('description', values?.description);

    console.log('selectedCompanies1', selectedUsers);
    console.log('selectedCompanies2', selectedCompanies);

    const userIds = selectedUsers?.length === 0 ? [] : selectedUsers?.map(user => user?._id);
    formData.append('users', JSON.stringify(userIds));
    // endUserList?.map(user => user?._id)

    const companiesIds = selectedCompanies?.length === 0 ? [] : selectedCompanies?.map(user => user?._id);
    formData.append('companyIds', JSON.stringify(companiesIds));
    // companiesList.map(user => user._id)

    const collectionsIds = selectedCollections?.length === 0 ? [] : selectedCollections?.map(user => user?._id);
    formData.append('collectionIds', JSON.stringify(collectionsIds));
    // collectionsList.map(user => user._id)

    if (values?.url) {
      formData.append('url', values?.url);
    }

    if (values?.group) {
      formData.append('groupType', values?.group);
    }
    // console.log('formData', formData);
    // for (let pair of formData.entries()) {
    //   console.log(`ttttt : ${pair[0]}: ${pair[1]}`);
    // }
    dispatch(createBroadcastChannel(formData));
    actions.resetForm();
    setSelectedUsers([]);
    setSelectedCompanies([]);
    setSelectedCollections([]);
    setSelectAll(false);
    setTimer(10);
  };

  const handleBackClick = () => {
    history.goBack();
  };

  const handleSend = () => {
    setIsRunning(true);
    setTimer(10);
  };

  const handleCancel = (resetForm, actions) => {
    setIsRunning(false);
    resetForm();
    setSelectedUsers([]);
    setSelectedCompanies([]);
    setSelectedCollections([]);
    setSelectAll(false);
    // setFieldValue();
    setTimer(10);
  };

  return (
    <>
      <PageContainer heading={'Broadcast Channel'} breadcrumbs={breadcrumbs}>
        <Button className={classes.backButton} variant="outlined" onClick={handleBackClick}>
          Back
        </Button>
        <Formik
          enableReinitialize={true}
          initialValues={{
            title: '',
            description: '',
            url: '',
            users: [],
            companies: [],
            collections: [],
            group: '',
          }}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            setFormikValues(values);
            setFormikActions(actions);
            handleSend();
          }}
          // onSubmit={BroadcastChannelFormHandler}
          // setSubmitting(false)
        >
          {({ errors, isSubmitting, values, setFieldValue, resetForm }) => {
            console.log('values Form', values, errors);
            return (
              <Form>
                <GridContainer>
                  <Grid item xs={12} lg={12} sm={12}>
                    <CmtCard alignItems="center">
                      <Box p={8} pb={0}>
                        <Grid container spacing={5}>
                          <Grid item xs={12}>
                            <Box alignItems="center">
                              <AppTextInput
                                id="title"
                                name="title"
                                size="medium"
                                label={'Title'}
                                value={values?.title}
                                onChange={e => setFieldValue('title', e.target.value)}
                                fullWidth
                                margin="0"
                                variant="outlined"
                              />
                              <ErrorMessage name="title" component="div" className="error" />
                            </Box>
                          </Grid>

                          <Grid item xs={12}>
                            <TextareaAutosize
                              className="textAreaControl"
                              aria-label="minimum height"
                              minRows={5}
                              style={{
                                width: '100%',
                                padding: '14px',
                                borderRadius: '4px',
                                borderColor: 'rgba(0, 0, 0, 0.23)',
                                fontSize: '14px',
                                color: 'rgba(0, 0, 0, 0.87)',
                              }}
                              placeholder="Description"
                              value={values.description}
                              onChange={e => setFieldValue(`description`, e.target.value)}
                            />
                            <ErrorMessage name={`description`} component="div" className="error" />
                          </Grid>

                          <Grid item xs={12}>
                            <Box alignItems="center">
                              <AppTextInput
                                id="url"
                                name="url"
                                size="medium"
                                label={'URL'}
                                value={values?.url}
                                onChange={e => setFieldValue('url', e.target.value)}
                                fullWidth
                                margin="0"
                                variant="outlined"
                              />
                              <ErrorMessage name="url" component="div" className="error" />
                            </Box>
                          </Grid>

                          <Grid item xs={12}>
                            <Box alignItems="center">
                              <Autocomplete
                                disabled={
                                  values?.group || selectedCollections?.length !== 0 || selectedCompanies?.length !== 0
                                    ? true
                                    : false
                                }
                                onKeyDown={e => {
                                  if (e.key === 'Enter') {
                                    e.preventDefault();
                                  }
                                }}
                                multiple
                                options={allOptions}
                                value={selectedUsers}
                                onChange={(event, value) => {
                                  if (value.some(option => option._id === 'all')) {
                                    // If "Select All" is selected, select all users
                                    setFieldValue(
                                      'users',
                                      endUserList.map(user => user._id),
                                    );
                                    setSelectedUsers(endUserList);
                                    setSelectAll(true);
                                  } else {
                                    const newSelectedUsers = value.filter(option => option._id !== 'all');
                                    setFieldValue(
                                      'users',
                                      newSelectedUsers.map(user => user._id),
                                    );
                                    setSelectedUsers(newSelectedUsers);
                                    setSelectAll(newSelectedUsers.length === endUserList.length);
                                  }
                                }}
                                disableCloseOnSelect
                                getOptionLabel={option => (option._id === 'all' ? 'Select All' : getDisplayName(option))}
                                renderOption={(option, { selected }) => (
                                  <>
                                    <Checkbox checked={option._id === 'all' ? selectAll : selected} />
                                    <ListItemText primary={option._id === 'all' ? 'Select All' : getDisplayName(option)} />
                                  </>
                                )}
                                renderTags={(value, getTagProps) => {
                                  if (selectAll) {
                                    return [
                                      <Chip
                                        label="Select All"
                                        {...getTagProps({ index: 0 })}
                                        onDelete={() => {
                                          setFieldValue('users', []);
                                          setSelectedUsers([]);
                                          setSelectAll(false);
                                        }}
                                      />,
                                    ];
                                  } else if (value.length > 2) {
                                    const firstTwoUsers = value.slice(0, 2);
                                    return [
                                      ...firstTwoUsers.map((option, index) => (
                                        <Chip label={getDisplayName(option)} {...getTagProps({ index })} key={option._id} />
                                      )),
                                      <Chip
                                        label={`+ ${value.length - 2} Others`}
                                        {...getTagProps({ index: 2 })}
                                        onDelete={() => {
                                          setFieldValue('users', []);
                                          setSelectedUsers([]);
                                          setSelectAll(false);
                                        }}
                                      />,
                                    ];
                                  } else {
                                    return value.map((option, index) => (
                                      <Chip label={getDisplayName(option)} {...getTagProps({ index })} key={option._id} />
                                    ));
                                  }
                                }}
                                // renderTags={(value, getTagProps) =>
                                //   selectAll
                                //     ? [
                                //         <Chip
                                //           label="Select All"
                                //           {...getTagProps({ index: 0 })}
                                //           onDelete={() => {
                                //             setFieldValue('users', []);
                                //             setSelectedUsers([]);
                                //             setSelectAll(false);
                                //           }}
                                //         />,
                                //       ]
                                //     : value.map((option, index) => (
                                //         <Chip label={getDisplayName(option)} {...getTagProps({ index })} key={option._id} />
                                //       ))
                                // }
                                renderInput={params => (
                                  <TextField {...params} variant="outlined" label="Select Users" placeholder="Users" />
                                )}
                              />
                              <span style={{ color: 'red' }}>{errorMessage}</span>
                            </Box>
                          </Grid>

                          <Grid item xs={12}>
                            <Box alignItems="center">
                              <Autocomplete
                                multiple
                                disabled={
                                  values?.group || selectedUsers?.length !== 0 || selectedCollections?.length !== 0
                                    ? true
                                    : false
                                }
                                onKeyDown={e => {
                                  if (e.key === 'Enter') {
                                    e.preventDefault();
                                  }
                                }}
                                options={companiesList}
                                value={selectedCompanies}
                                onChange={(event, value) => {
                                  if (value.some(option => option._id === 'all')) {
                                    setFieldValue(
                                      'companies',
                                      companiesList.map(user => user._id),
                                    );
                                    setSelectedCompanies(companiesList);
                                    setSelectAll(true);
                                  } else {
                                    const newSelectedCompanies = value.filter(option => option._id !== 'all');
                                    setFieldValue(
                                      'companies',
                                      newSelectedCompanies.map(user => user._id),
                                    );
                                    setSelectedCompanies(newSelectedCompanies);
                                    setSelectAll(newSelectedCompanies.length === companiesList.length);
                                  }
                                }}
                                disableCloseOnSelect
                                getOptionLabel={option =>
                                  option._id === 'all' ? 'Select All' : getDisplayCompaniesName(option)
                                }
                                renderOption={(option, { selected }) => (
                                  <>
                                    <Checkbox checked={option._id === 'all' ? selectAll : selected} />
                                    <ListItemText
                                      primary={option._id === 'all' ? 'Select All' : getDisplayCompaniesName(option)}
                                    />
                                  </>
                                )}
                                renderTags={(value, getTagProps) =>
                                  selectAll
                                    ? [
                                        <Chip
                                          label="Select All"
                                          {...getTagProps({ index: 0 })}
                                          onDelete={() => {
                                            setFieldValue('companies', []);
                                            setSelectedCompanies([]);
                                            setSelectAll(false);
                                          }}
                                        />,
                                      ]
                                    : value.map((option, index) => (
                                        <Chip
                                          label={getDisplayCompaniesName(option)}
                                          {...getTagProps({ index })}
                                          key={option._id}
                                        />
                                      ))
                                }
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Select Companies"
                                    placeholder="Companies"
                                  />
                                )}
                              />
                              <span style={{ color: 'red' }}>{errorMessage}</span>
                            </Box>
                          </Grid>

                          <Grid item xs={12}>
                            <Box alignItems="center">
                              <Autocomplete
                                multiple
                                options={collectionsList}
                                disabled={
                                  values?.group || selectedUsers?.length !== 0 || selectedCompanies?.length !== 0
                                    ? true
                                    : false
                                }
                                onKeyDown={e => {
                                  if (e.key === 'Enter') {
                                    e.preventDefault();
                                  }
                                }}
                                value={selectedCollections}
                                onChange={(event, value) => {
                                  if (value.some(option => option._id === 'all')) {
                                    setFieldValue(
                                      'collections',
                                      collectionsList.map(user => user._id),
                                    );
                                    setSelectedCollections(collectionsList);
                                    setSelectAll(true);
                                  } else {
                                    const newSelectedCollections = value.filter(option => option._id !== 'all');
                                    setFieldValue(
                                      'collections',
                                      newSelectedCollections.map(user => user._id),
                                    );
                                    setSelectedCollections(newSelectedCollections);
                                    setSelectAll(newSelectedCollections.length === collectionsList.length);
                                  }
                                }}
                                disableCloseOnSelect
                                getOptionLabel={option =>
                                  option._id === 'all' ? 'Select All' : getDisplayCollectionName(option)
                                }
                                renderOption={(option, { selected }) => (
                                  <>
                                    <Checkbox checked={option._id === 'all' ? selectAll : selected} />
                                    <ListItemText
                                      primary={option._id === 'all' ? 'Select All' : getDisplayCollectionName(option)}
                                    />
                                  </>
                                )}
                                renderTags={(value, getTagProps) =>
                                  selectAll
                                    ? [
                                        <Chip
                                          label="Select All"
                                          {...getTagProps({ index: 0 })}
                                          onDelete={() => {
                                            setFieldValue('collections', []);
                                            setSelectedCollections([]);
                                            setSelectAll(false);
                                          }}
                                        />,
                                      ]
                                    : value.map((option, index) => (
                                        <Chip
                                          label={getDisplayCollectionName(option)}
                                          {...getTagProps({ index })}
                                          key={option._id}
                                        />
                                      ))
                                }
                                renderInput={params => (
                                  <TextField {...params} variant="outlined" label="Select Collection" placeholder="Users" />
                                )}
                              />
                              <span style={{ color: 'red' }}>{errorMessage}</span>
                            </Box>
                          </Grid>

                          <Grid item xs={12}>
                            <Box alignItems="center">
                              <Autocomplete
                                multiple={false}
                                disabled={
                                  selectedCollections?.length !== 0 ||
                                  selectedUsers?.length !== 0 ||
                                  selectedCompanies?.length !== 0
                                    ? true
                                    : false
                                }
                                options={groupTypes}
                                value={selectedGroup}
                                onChange={(event, value) => {
                                  if (value) {
                                    setFieldValue('group', value?._id);
                                    setSelectedGroup(value);
                                    setSelectAll(false);
                                  } else {
                                    setFieldValue('group', '');
                                    setSelectedGroup('');
                                    setSelectAll(false);
                                  }
                                }}
                                // disableCloseOnSelect
                                getOptionLabel={option => getGroupName(option)}
                                renderOption={(option, { selected }) => (
                                  <>
                                    <Checkbox checked={selected} />
                                    <ListItemText primary={getGroupName(option)} />
                                  </>
                                )}
                                renderTags={(value, getTagProps) =>
                                  value.map((option, index) => (
                                    <Chip label={getGroupName(option)} {...getTagProps({ index })} key={option?.label} />
                                  ))
                                }
                                renderInput={params => (
                                  <TextField {...params} variant="outlined" label="Select Group" placeholder="Group" />
                                )}
                              />
                              <span style={{ color: 'red' }}>{errorMessage}</span>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>

                      <Box p={8}>
                        {isRunning ? (
                          <>
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              className={classes.cancelButton}
                              onClick={() => {
                                // values.title = '';
                                // setFieldValue('');
                                handleCancel(resetForm);
                              }}
                              startIcon={<SendIcon />}>
                              Cancel
                            </Button>
                            <span style={{ color: 'red', marginLeft: '10px' }}> Cancel in {timer} seconds </span>
                          </>
                        ) : (
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            // onClick={() => handleSend}
                            startIcon={<SendIcon />}>
                            Send
                          </Button>
                        )}
                      </Box>
                    </CmtCard>
                  </Grid>
                </GridContainer>
              </Form>
            );
          }}
        </Formik>
      </PageContainer>
    </>
  );
};

export default BroadcastChannelPage;
