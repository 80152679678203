import axios from '../../services/auth/jwt/config';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import {
  FETCH_DATA_SUCCESS,
  GET_COLLECTION_LIST,
  GET_END_USERS,
  GET_ENDUSERS_DETAILS,
  GET_ENDUSERSFORTABLE,
} from '../../@jumbo/constants/ActionTypes';
import {
  endUsersList,
  endUserStatusChange,
  endUsersDetails,
  endUsersExport,
  endUsersAllowAccess,
  collectionList,
} from '../../@jumbo/constants/ApiConstant';
import { history } from 'redux/store';
import { setAuthUser } from 'redux/actions/Auth';

// get sub admin list
export const getEndUserList = (sortBy, sort, searchTerm, page, limit, filterOptions, endUser, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());

    axios
      .get(endUsersList, {
        params: {
          sortBy: `${sortBy}:${sort}`,
          search: searchTerm,
          page: page + 1,
          limit: limit,
          status: filterOptions[0],
          user_type: endUser,
        },
      })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: FETCH_DATA_SUCCESS });
          dispatch({ type: GET_END_USERS, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'End user list not found.') {
          if (callbackFun) callbackFun();
          dispatch(fetchError());
          dispatch({ type: GET_END_USERS, payload: [] });
        } else if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

export const getCollectionList = (sortBy, sort, searchTerm, page, limit, filterOptions, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());

    axios
      .get(collectionList, {
        params: {
          sortBy: `${sortBy}:${sort}`,
          search: searchTerm,
          page: page + 1,
          limit: limit,
          status: filterOptions[0],
        },
      })
      .then(data => {
        console.log('data2', data);

        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: FETCH_DATA_SUCCESS });
          dispatch({ type: GET_COLLECTION_LIST, payload: data.data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Collection list not found.') {
          if (callbackFun) callbackFun();
          dispatch(fetchError());
          dispatch({ type: GET_COLLECTION_LIST, payload: [] });
        } else if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

export const updateEndUserManagementStatus = (data, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(endUserStatusChange, data)
      .then(response => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('End user status updated successfully.'));
          }, 2000);
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

// set current user for view tab
export const setCurrentEndUsersManagement = user => {
  return dispatch => {
    dispatch({ type: GET_ENDUSERS_DETAILS, payload: user });
  };
};

export const getEndUserDetails = (userId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(endUsersDetails + userId)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_ENDUSERS_DETAILS, payload: data.data.data });

          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.data?.message === 'Sub admin List not found.') {
          dispatch(fetchError(error?.response?.data?.message));
          dispatch({ type: GET_ENDUSERS_DETAILS, payload: [] });
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

export const getExportEndUserListFile = (userId, callbackFun, afterErrorClose) => {
  return dispatch => {
    dispatch(fetchStart());
    axios({
      method: 'get',
      url: `${endUsersExport}`,
      // endUsersExport
      // url: 'https://node.inheritxdev.in/underwriter-dev/v1/admin/export-end-users',
      responseType: 'blob',
    })
      .then(response => {
        console.log('ressssssssssssss', response);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
        dispatch(fetchSuccess('End users data exported successfully.'));
        if (callbackFun) callbackFun(response.data);
      })
      .catch(error => {
        if (error?.response?.status === 400) {
          dispatch(fetchError('No data found.'));
          afterErrorClose.onCloseDialog();
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};

export const updateEndUsersAllowAccess = (data, list) => {
  console.log('datadata', data, list);

  return dispatch => {
    dispatch(fetchStart());
    axios
      .put(endUsersAllowAccess, data)
      .then(response => {
        if (response) {
          // console.log("response",response)
          dispatch({ type: GET_END_USERS, payload: list });
          setTimeout(() => {
            dispatch(fetchSuccess(response?.data?.message));
            // dispatch(fetchSuccess('Make default updated successfully.'));
          }, 2000);
          // if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          dispatch(setAuthUser(null));
          history.push('/signin');
        } else {
          dispatch(fetchError(error?.response?.data?.message));
        }
      });
  };
};
