import { ADD_BROADCAST } from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  users: [],
  currentUser: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_BROADCAST: {
      return {
        ...state,
        users: [action.payload, ...state.users],
      };
    }
    default:
      return state;
  }
};
