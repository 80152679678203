import axios from 'axios';

export const baseURL = `https://api.iamunderwriter.com/v9`; // Prod Client Server
// export const baseURL = `https://underwriter-dev.inheritxdev.in/v9`; // Inx Dev Server
// export const baseURL = `https://giraffe-resolved-jolly.ngrok-free.app/v8`; // Ngrok Server

export default axios.create({
  // baseURL: `https://finnhub.io/api/v1/`, //YOUR_API_URL HERE
  // baseURL: `https://node.inheritxdev.in/underwriter-dev/v1/`, //YOUR_API_URL HERE
  // baseURL: `https://node.inheritxdev.in/underwriter-qa/v1/`, //YOUR_API_URL HERE
  // baseURL: `https://node.inheritxdev.in/underwriter-prod/v1/`, //YOUR_API_URL HERE
  // baseURL: `https://underwriter-dev.inheritxdev.in/v1/`, //YOUR_API_URL HERE
  // baseURL: `https://underwriter-prod.inheritxdev.in/v1`, //YOUR_API_URL HERE

  // baseURL: `https://api.iamunderwriter.com/v8`, // YOUR_API_URL_HERE
  // baseURL: `https://underwriter-dev.inheritxdev.in/v8`, // YOUR_API_URL_HERE
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});
