import axios from '../../services/auth/jwt/config';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import { GET_SUBADMIN, FETCH_DATA_SUCCESS, GET_SUBADMIN_DETAILS } from '../../@jumbo/constants/ActionTypes';
import { broadcastChannel } from '../../@jumbo/constants/ApiConstant';
import { history } from 'redux/store';
import { setAuthUser } from 'redux/actions/Auth';

export const createBroadcastChannel = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(broadcastChannel, user)
      .then(data => {
        if (data) {
          setTimeout(() => {
            dispatch(fetchSuccess('New broadcast send successfully.'));
          }, 2000);
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
      });
  };
};
